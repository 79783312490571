// @ts-nocheck
import {useState, useEffect} from 'react';
import dynamic from 'next/dynamic';
import {handleSendOTP, handleVerifyOTP} from 'handlers/login';
import {useRouter} from "next/router";
import Image from "next/image";
import cloutLogo from 'assets/clout_logo.svg';
import Link from "next/link";
import { addInstallStoreSetup } from 'data/shopify';
import {getState} from "utils/misc";

const Loader = dynamic(() => import('components/Loader/_default/Loader'));

const LoginPage = () => {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [OTP, setOTP] = useState('');
  const [authToken, setAuthToken] = useState('');
  const [resendTimer, setResendTimer] = useState(0);
  const [timerIntervalId, setTimerIntervalId]: [any, Function] = useState(0);

  useEffect(() => { // to clear Freshchat widget context
    const intervalId = setInterval(() => {
      if (window.fcWidget?.isLoaded()) {
        clearInterval(intervalId);
        window.fcWidget.user.clear().then(
          () => {
            window.fcWidget.setExternalId(null); // Clear the user context
            window.fcWidget.destroy();
          },
          () => { window.fcWidget.hide(); }
        );
      }
    }, 100);

    return () => clearInterval(intervalId);
  }, []);
  const installStoreSetupAPI = async () => {
    try {
      const {code, shop} = getState(router);

      if (code && shop) {
        await addInstallStoreSetup({ code: code || "", shop: shop || "" });
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    installStoreSetupAPI();
  }, []);

  const handlePhoneNumberChange = (e: any) => {
    const {value} = e.target;
    if (!value || (value.length <= 10 && /^[6-9][0-9]*$/.test(value))) {
      setPhoneNumber(value);
    }
  }

  const handleOTPChange = (e: any) => {
    const {value} = e.target;
    if (!value || (value.length <= 4 && /^[0-9]+$/.test(value))) {
      setOTP(value);
    }
  }

  const resendTimerPollingFunction = () => {
    setResendTimer(resendTimer => resendTimer - 1);
  };

  const startResendCount = () => {
    setResendTimer(60);
    setTimerIntervalId(setInterval(resendTimerPollingFunction, 1000));
  };

  useEffect(() => {
    if (resendTimer === 0) {
      clearInterval(timerIntervalId);
    }
  }, [resendTimer]);

  const handleGetOtpSubmit = (e: any) => {
    e.preventDefault();
    handleSendOTP(phoneNumber, setAuthToken, setPageIndex, startResendCount, setIsLoading);
  };

  const handleOtpVerification = (e: any) => {
    e.preventDefault();
    handleVerifyOTP(OTP, authToken, router, setIsLoading);
  };

  const login = (
    <div className={'bg-theme_1_white rounded-[10px] shadow-[0_0_30px_0px_rgba(0,0,0,0.1)] p-[20px] max-w-[386px] w-[90%]  m-auto'}>
      <span className={'text-theme_1_black font-bold text-[24px] mt-[10px]'}>Sign In</span>
      <hr className={'my-[20px] border-theme_1_linebreak'}/>
      <form onSubmit={handleGetOtpSubmit}>
        <label>Phone No.</label>
        <div className={'flex border border-theme_1_border p-2 mb-2'}>
          <span className={'font-semibold border-r-[1px] pr-2'}>+91</span>
          <input
            id='login-page-phone-input'
            type="number"
            className={'w-full pl-2 smScreen:text-base'}
            onChange={handlePhoneNumberChange}
            value={phoneNumber}
          />
        </div>
        <small className="text-[#757575] text-[12px]">
          By continuing you agree to Clout 
          <a 
            href="/tnc" 
            target={"_blank"}
          >
            {" "}<b className="underline">{"T&C"}</b>{" "}
          </a> 
          and 
          <a
            href="/privacy" 
            target={"_blank"}
          >
            {" "}<b className="underline">Privacy Policy</b>
          </a>.
        </small>
        <button
          type="submit"
          id='login-page-get-otp-button'
          className={'w-full bg-theme_1_black text-theme_1_white p-2 mt-[80px] font-semibold disabled:opacity-70'}
          // onClick={() => handleSendOTP(phoneNumber, setAuthToken, setPageIndex, startResendCount, setIsLoading)}
          disabled={phoneNumber.length < 10}
        >
          Get OTP
        </button>
      </form>
    </div>
  );

  const verify = (
    <div className={'bg-theme_1_white rounded-[10px] shadow-[0_0_30px_0px_rgba(0,0,0,0.1)] p-[20px] max-w-[386px] w-[90%]  m-auto'}>
      <span className={'text-theme_1_black font-bold text-[24px] mt-[10px]'}>Sign In</span>
      <hr className={'my-[20px] border-theme_1_border'}/>
      <form onSubmit={handleOtpVerification}>
        <div>
          <div className={'font-bold'}>Verify Phone No.</div>
          <div className={'text-[12px] text-theme_1_greyText'}>OTP sent to <span
            className={'font-semibold'}>+91-{phoneNumber}</span><a
            className={'ml-2 font-semibold hover:underline hover:cursor-pointer'}
            onClick={() => {
              setPageIndex(0);
              setOTP('');
            }}
          >
            Change?
          </a></div>
        </div>
        <div className={'mt-5'}>
          <label className={'flex justify-between'}>
            <span className={'text-theme_1_greyText'}>Enter 4 digit OTP</span>
            <span className={'text-[12px]'}>
              <a
                id='login-page-resend-otp-button'
                className={`font-semibold hover:underline hover:cursor-pointer ${resendTimer > 0 ? 'pointer-events-none opacity-50' : ''}`}
                onClick={() => handleSendOTP(phoneNumber, setAuthToken, setPageIndex, startResendCount, setIsLoading)}
              >
                Resend OTP
              </a>
              {resendTimer > 0 && (<span>
                {' '}
                in {resendTimer} Sec
              </span>)}
            </span>
          </label>
          <div className={'flex border-[1px] p-2'}>
            <input
              id='login-page-otp-input'
              type="number"
              className={'w-full border-theme_1_border tracking-[10px] smScreen:text-base'}
              onChange={handleOTPChange}
              value={OTP}
            />
          </div>
        </div>
        <button
          id='login-page-proceed-button'
          type="submit"
          className={'w-full bg-theme_1_black text-theme_1_white p-2 mt-[40px] font-semibold disabled:opacity-70'}
          // onClick={() => handleVerifyOTP(OTP, authToken, router, setIsLoading)}
          disabled={OTP.length < 4}
        >
          Verify & Proceed
        </button>
      </form>
    </div>
  );

  return (<div className={'font-[Inter] text-theme_1_grey1 text-[14px] pt-14 w-screen h-screen bg-theme_1_pageBg'}>
    <div className={'text-center p-[30px] mt-[50px]'}>
      <Image src={cloutLogo}
             alt={'clout-logo'}
             loading="eager"
             width={82}
             height={40}
      />
    </div>
    {pageIndex === 0 && <div>
      {login}
      <div className="flex justify-center mt-4">
        <Link passHref
              href={`/staff-login?${new URLSearchParams(getState(router)).toString()}`}>
          <a className="underline">
            Sign in as Staff
          </a>

        </Link>
      </div>
    </div> }
    {pageIndex === 1 && verify}
    <Loader isVisible={isLoading}/>
  </div>);
}

export default LoginPage;
